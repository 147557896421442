.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
}

.pagination li:not(:last-child) {
  margin-left: 3px;
}

.pagination .previous {
  margin-right: 5px;
  margin-top: 2px;
}

.pagination .next {
  margin-left: 10px;
  margin-top: 2px;
}

.pagination .active {
  background-color: #1e50ff;
  border-radius: 50%;
  color: #fff;
}

.pagination .page-item {
  list-style: none;
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  height: 31.5px;
  width: 31.5px;
}
